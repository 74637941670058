<template>
	<div class="grid">
		<div class="md:col-6">
			<Toolbar class="field" style="border:0px; padding:0rem 0rem 0rem 0.5rem">
				<template #start>
					<Button :label="$t('button.back')" icon="pi pi-backward" @click="onBack()"/>
				</template>
			</Toolbar>
			<div class="card p-fluid">
				<h5>{{ entity.packageCode}}</h5>
				<div class="field grid">
					<label for="weight" class="mb-2 md:col-2 md:mb-0">{{$t('receipt.weight')}}</label>
					<div class="md:col-2 p-inputgroup">
						<InputNumber id="weight" v-model="entity.weightLbs" mode="decimal" disabled/>
						<span class="p-inputgroup-addon">{{$t('unit.lbs')}}</span>
					</div>
					<div class="md:col-2 p-inputgroup">
						<InputNumber id="weight-kg" v-model="weightKg" mode="decimal" :min="0" disabled/>
						<span class="p-inputgroup-addon">{{$t('unit.kg')}}</span>
					</div>
					<label for="weight" class="mb-2 md:col-2 md:mb-0">{{$t('receipt.actualWeight')}}</label>
					<div class="md:col-2 p-inputgroup">
						<InputNumber id="weight" v-model="entity.weightLbs" mode="decimal" disabled/>
						<span class="p-inputgroup-addon">{{$t('unit.lbs')}}</span>
					</div>
					<div class="md:col-2 p-inputgroup">
						<InputNumber id="weight-kg" v-model="weightKg" mode="decimal" :min="0" disabled/>
						<span class="p-inputgroup-addon">{{$t('unit.kg')}}</span>
					</div>
				</div>
				<div class="field grid">
					<label for="recipient" class="mb-2 md:col-2 md:mb-0">{{$t('receipt.recipientName')}}</label>
					<span class="md:col-4">{{entity.receipt.recipientName}}</span>
					<label for="recipientphone" class="mb-2 md:col-2 md:mb-0">{{$t('receipt.recipientPhone')}}</label>
					<span>{{entity.receipt.recipientPhone}}</span>
				</div>
				<div class="field grid">
					<label for="recipient_address" class="col-12 md:col-2">{{$t('receipt.recipientAddress')}}</label>
					<span class="md:col-10">{{recipientAddress}}</span>
				</div>
				<div class="field grid">
					<label for="service" class="mb-2 md:col-2 md:mb-0">{{$t('receipt.service')}}</label>
					<span class="md:col-4">{{entity.receipt.deliveryType}}</span>
					<label for="payment_status" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('receipt.paymentStatus')}}</label>
					<span class="md:col-4">{{paymentStatus}}</span>
				</div>
				<div class="field grid">
					<label for="insurance" class="mb-2 md:col-2 md:mb-0">{{$t('receipt.insurance_fee')}}</label>
					<div class="md:col-4">
						<InputNumber id="insurance" mode="currency" currency="USD" v-model="entity.receipt.insuranceFee" disabled/>
					</div>
					<label for="extra_tax" class="mb-2 md:col-2 md:mb-0">{{$t('receipt.extra_tax')}}</label>
					<div class="md:col-4">
						<InputNumber id="extra_tax" mode="currency" currency="USD" v-model="entity.receipt.extraTax" disabled/>
					</div>
				</div>

				<div class="field grid">
					<label for="delivery_note" class="mb-2 md:col-2 md:mb-0">{{$t('receipt.delivery_note')}}</label>
					<span class="md:col-4">{{entity.receipt.deliveryNote}}</span>
					<label for="delivery_note" class="mb-2 md:col-2 md:mb-0">{{$t('receipt.note')}}</label>
					<span class="md:col-4">{{entity.receipt.note}}</span>
				</div>
			</div>
			<Toolbar class="field" style="border:0px; padding:0rem 0rem 0rem 0.5rem">
				<template #start>
					<Button :label="$t('button.save')" icon="pi pi-save" @click="onSave($event)"/>
					<i class="mr-2" />
					<Button :label="$t('button.reset')" icon="pi pi-replay" @click="onReset($event)"/>
				</template>
			</Toolbar>
			<div class="card p-fluid">
				<div class="field">
					<label for="abnormal" class="mb-2 md:col-2 md:mb-0" >{{$t('package.abnormal')}}</label>
					<Checkbox id="abnormal" v-model="packageNote.abnormal" :binary="true" />
				</div>
				<div class="field grid">
					<label for="note" class="mb-2 md:col-2 md:mb-0">{{$t('worklog.note')}}</label>
					<form-field :message="errors.node" class="col-12 md:col-4">
						<Textarea ref="message" id="note" v-model="packageNote.customNote" :required="true" type="text" rows="3"/>
					</form-field>
					<label for="message" class="mb-2 md:col-2 md:mb-0">{{$t('worklog.message')}}</label>
					<form-field :message="errors.message" class="col-12 md:col-4">
						<Textarea ref="message" id="message" v-model="packageNote.storeNote" :required="true" type="text" rows="3"/>
					</form-field>
				</div>
			</div>
		</div>
		<div class="md:col-6">
			<TabView>
				<TabPanel :header="$t('package.manifests')" >
					<CrudTable :entities="entity.packageItems" :meta="metaItemTable"/>
        </TabPanel>
				<TabPanel :header="$t('package.images')">
					<Galleria :value="images" :numVisible="5" :circular="true" containerStyle="max-width: 640px"
						:showItemNavigators="true" :showThumbnails="true">
						<template #item="slotProps">
							<img :src="slotProps.item.imageSrc" :alt="slotProps.item.alt" style="width: 100%; display: block;" />
						</template>
						<template #thumbnail="slotProps">
							<img :src="slotProps.item.thumbnailSrc" :alt="slotProps.item.alt" style="display: block;" />
						</template>
					</Galleria>
				</TabPanel>
				<TabPanel :header="$t('package.worklog')">
						<Timeline :value="worklogs" align="alternate" class="customized-timeline">
								<template #marker="slotProps">
										<span class="custom-marker shadow-2" :style="{backgroundColor: slotProps.item.color}">
												<i :class="slotProps.item.icon"></i>
										</span>
								</template>
								<template #content="{item, index}">
										<Card>
												<template #title>
														<Button icon="pi pi-check" v-if="index==0" class="p-button-rounded p-button-text" @click="onSaveWorklog($event)"/>
														{{item.author}} - {{item.createdDate}}
												</template>
												<template #content>
														<Textarea v-model="item.message" rows="3" v-if="index==0"/>
														<span v-if="index>0">{{item.message}}</span>
												</template>
										</Card>
								</template>
						</Timeline>
				</TabPanel>
			</TabView>
		</div>
	</div>
</template>

<script>
	import Timeline from '@/components/Timeline'
	import {FilterMatchMode} from 'primevue/api';
	import CrudTable from '@/pages/common/CrudTable'
	import AddressService from '@/service/AddressService'
	import ReceiptService from '@/service/ReceiptService'
	import PackageService from '@/service/PackageService';
	import ImageService from '@/service/ImageService';
	import {Service} from '@/service/common';
	import AuthService from '@/service/AuthService'
	import FormField from '@/components/FormField'
	import {isPositiveNumber} from '@/utils/common'
	import { Consts } from '@/utils/consts'
	import formatter from '@/utils/formatter';
	import form from '@/mixins/form';
	const resourceField = 'packageCode'
	export default {
		components: {CrudTable, FormField, Timeline},
		mixins:[form],
		data() {
			return {
				entity: {
					receipt: {}
				},   //Package entity
				images: [],
				submitted: false,
				packageNote: {},
				worklogs: [],
				errors:{},
				metaItemTable: {
					entityName: "packageItem",
					expander: false,
					editMode:"cell",
					paginator:{
						enable: false,
						rows: 10
					},
					columns: [
						{
							name: "name",
							headerStyle: "width:15%; min-width:10rem;"
						},
						{
							name: "quantity",
							headerStyle: "width:15%; min-width:6rem;"
						},
						{
							name: "electronic",
							headerStyle: "width:15%; min-width:6rem;",
							editor: {
								enable:true
							}
						},
						{
							name: "description",
							headerStyle: "min-width:10rem;",
						}
					],
					tableActions: [],
					itemActions: []
				}
			}
		},
		created(){
			this.worklogService = new Service('worklog');
		},
		mounted() {
			if(this.$route.params.id) {
				let route_id = this.$route.params.id;
				PackageService.get(route_id).then(data => {
					this.entity = data;
					ImageService.loadImages(data).then(images => this.images = images);
					this.worklogService.filter({
						resourceType: 'package',
						resourceCode: data.packageCode
					}).then(data => {
							if (Array.isArray(data)) {
								data.forEach(item => item.createdDate = formatter.formatDate(new Date(item.createdDate)))
							}
							let content = data || [];
							content.unshift({
								author: this.currentUser.userFullName,
								createdDate: formatter.formatDate(new Date())

							})
							this.worklogs = content
						});
				});

			}
		},
		updated() {

		},
		computed: {
			currentUser() {
				return AuthService.currentUser;
			},
			weightKg() {
				return this.entity.weightLbs * Consts.POUND2KG;
			},
			recipientAddress() {
				let arrs = [];
				if (this.entity.receipt) {
					let receipt = this.entity.receipt;
					arrs.push(receipt.recipientAddress);
					if (receipt.recipientWard) {
						arrs.push(receipt.recipientWard);
					}
					if (receipt.recipientDistrict) {
						arrs.push(receipt.recipientDistrict);
					}
					if (receipt.recipientProvince) {
						arrs.push(receipt.recipientProvince);
					}
				}
				return arrs.join(', ');
			},
			paymentStatus() {
				return this.entity.receipt.paymentStatus ? this.$t('receipt.paymentStatuses.' + this.entity.receipt.paymentStatus) : '';
			}
		},
		methods: {
			loadReceipt(id) {
				ReceiptService.get(id).then(data => {
					this._setReceipt(data);
				})
			},
			_setReceipt(receipt) {
				if(receipt) {
					this.originalEntity = receipt
					this.receipt = receipt
					this.weightKg = receipt.weight * Consts.POUND2KG
					if(receipt.id) {
						this.showPackageTable = true;
					}
				}
			},
			onBack() {
				this.$router.back()
			},
			onSaveWorklog() {
				let worklog = {
					message: this.worklogs[0].message,
					resourceType: 'package',
					resourceField: resourceField,
					resourceCode: this.entity[resourceField]
				};
				this.worklogService.save(worklog).then(res => {
					this.worklogs[0].id = res.id;
					this.worklogs.unshift({
						author: this.currentUser.userFullName,
						createdDate: formatter.formatDate(new Date())
					});
					console.log(res)
				});
			},
			onPreview() {
				this.$router.push({ name: 'print_receipt', params: {id: this.receipt.id} });
			},
			onSearchSender() {
				if(this.receipt.senderPhone) {
					AddressService.findByPhoneNumber(this.receipt.senderPhone,'sender')
						.then((response) => {
								const data = response.content;
								if(Array.isArray(data) && data.length > 0) {
									let receipt = this.receipt;
									receipt.senderName = data[0].contactName;
									if (typeof data[0].state == 'string') {
										this._setSenderState(data[0].state);
									}
									if (typeof data[0].county == 'string') {
										this._setSenderCounty(data[0].county);
									}
									if (typeof data[0].city == 'string') {
										this._setSenderCity(data[0].city);
									}
									receipt.senderAddress = data[0].detail;
									this.receipt = receipt;
								}
						})
				}
			},
			_setSenderState(value) {
				if(Array.isArray(this.usStates)) {
					let lcValue = value.trim().toLowerCase();
					for(let i = 0; i < this.usStates.length; i++) {
						if (this.usStates[i].toLowerCase().indexOf(lcValue) >= 0) {
							this.receipt.senderState = this.usStates[i];
							if (!this.receipt.id) {
								this.receipt.senderCounty = null;
								this.receipt.senderCity = null;
							}
							break;
						}
					}
				}
				if (this.usGeoInfos && this.receipt.senderState) {
					const counties = this.usGeoInfos[this.receipt.senderState];
					if(typeof counties == 'object') {
						this.usCounties = Object.keys(counties);
					}
				}
			},
			_setSenderCounty(value) {
				if(Array.isArray(this.usCounties)) {
					let lcValue = value.trim().toLowerCase();
					for(let i = 0; i < this.usCounties.length; i++) {
						if (this.usCounties[i].toLowerCase().indexOf(lcValue) >= 0) {
							this.receipt.senderCounty = this.usCounties[i];
							if (!this.receipt.id) {
								this.receipt.senderCity = null;
							}
							break;
						}
					}
				}
				if (this.usGeoInfos && this.receipt.senderState && this.receipt.senderCounty) {
					const counties = this.usGeoInfos[this.receipt.senderState];
					const cities = counties[this.receipt.senderCounty];
					if (typeof cities == 'object') {
						this.usCities = Object.keys(cities);
					}
				}
			},
			_setSenderCity(value) {
				if(Array.isArray(this.usCities)) {
					let lcValue = value.trim().toLowerCase();
					for(let i = 0; i < this.usCities.length; i++) {
						if (this.usCities[i].toLowerCase().indexOf(lcValue) >= 0) {
							this.receipt.senderCity = this.usCities[i];
							break;
						}
					}
				}
			},
			onSearchRecipient() {
				if (this.receipt.recipientPhone) {
					AddressService.findByPhoneNumber(this.receipt.recipientPhone,'recipient')
						.then((response) => {
							const data = response.content;
							if(Array.isArray(data) && data.length > 0) {
								this.receipt.recipientName = data[0].contactName;
								this._setRecipientProvince(data[0].province);
								this._setRecipientDistrict(data[0].district);
								this._setRecipientWard(data[0].ward);
								this.receipt.recipientAddress = data[0].detail;
							}
						})
					}
			},
			onChangeWeight(event) {
				console.log(this.receipt);
				console.log(Consts.POUND2KG);
				try {
					let value = parseFloat(event.value);
					this.weightKg = value * Consts.POUND2KG;
				}
				catch(e) {console.debug(3)}
			},
			onChangeService(event) {
				this.receipt.deliveryType = event.value;
			},
			onCellEditComplete(event) {
				let { data, newValue, field } = event;
				switch (field) {
						case 'weightLbs':
								if (isPositiveNumber(newValue)) {
										data[field] = newValue;
										PackageService.updateField(data.id, field, newValue);
								} else {
										event.preventDefault();
								}
								break;

						default:
								if (newValue.trim().length > 0) {
										data[field] = newValue;
										PackageService.updateField(data.id, field, newValue);
								} else {
										event.preventDefault();
								}
						break;
				}
			},
			onSave() {
				if(!this.submitted) {
					this.errors = this.validate(this.receipt, 'receipt');
					if(Object.keys(this.errors).length == 0) {
						this.submitted = true;
						if (this.receipt.id) {
							ReceiptService.update(this.receipt.id, this.receipt).then((response)=>{
								this._setReceipt(response);
								this.submitted = false;
							});
						} else {
							ReceiptService.create(this.receipt).then((response)=>{
								this._setReceipt(response);
								this.submitted = false;
							});
						}
					}
				}
			},
			onReset() {
				this.receipt = this.originalEntity;
			},
			onTableAction(action) {
				if(action == 'create') {
					console.log("Call packageEdit");
				} else if (action == 'packageedit') {
					this.$router.push({ name: 'users_receipt_package',params: {receiptId: this.receipt.id}})
				} else if (action == 'delete') {
					console.log("Call packageDelete");
				}
			},
			onItemAction(action, entity) {
				if(action == 'edit') {
					console.log("Call packageEdit");
					console.log(entity);
					this.$router.push({ name: 'users_receipt_package', params: {receiptId: this.receipt.id, packageId: entity.id} })
				} else if (action == 'delete') {
					console.log("Call packageDelete");
					console.log(entity);
				} else if (action == 'qrcode') {
					console.log(entity);
				}
			},
			initManifestFilters() {
          this.filters = {
              'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
          }
      }
		}
	}
</script>
<style lang="scss" scoped>
.narrow .p-toolbar {
  padding: 0 1rem;
  margin: -0.5rem 0;
  border: 0;
  font-weight: 600;
}
</style>
